<template>
  <div id="div-tabela-kpis-by-municipio">
    <v-text-field
      v-if="numMunicipios > 10"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-5 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="data-table-kpis"
      multi-sort
      dense
      :headers="headers"
      :items="dados"
      :search.sync="search"
      :sort-by="['qrt']"
      :sort-desc="['qrt']"
      :options="{
        itemsPerPage: 10,
      }"
      :headerProps="{
        sortByText: 'Ordenar Por',
      }"
      :hide-default-footer="numMunicipios <= 10"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text.replaceAll("_", " ") }}</span>
          </template>
          <span>{{ traducaoIndicadores[h.value] }}</span>
        </v-tooltip>
        <span v-else>{{ h.text.replaceAll("_", " ") }}</span>
      </template>

      <template v-slot:[`item.qrt`]="{ item }">
        {{ item.qrt | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.prp`]="{ item }">
        {{ item.prp | parseNumberToFloatBrIgnoringNaN }} %
      </template>
      <template v-slot:[`item.pri`]="{ item }">
        {{ item.pri | parseNumberToFloatBrIgnoringNaN }} %
      </template>
      <template
        v-slot:[`item.prazo_medio_total_reclamacoes_encerradas`]="{ item }"
      >
        {{
          item.prazo_medio_total_reclamacoes_encerradas
            | parseNumberToFloatBrIgnoringNaN
        }}
      </template>
      <template
        v-slot:[`item.prazo_medio_total_reclamacoes_improcedentes_encerradas`]="{
          item,
        }"
      >
        {{
          item.prazo_medio_total_reclamacoes_improcedentes_encerradas
            | parseNumberToFloatBrIgnoringNaN
        }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    name: "KpisByMunicipioDataTable",
    props: {
      dados: {
        type: Array,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
    },
    data: () => ({
      kpis: [
        "qrt",
        "prp",
        "pri",
        "prazo_medio_total_reclamacoes_encerradas",
        "prazo_medio_total_reclamacoes_improcedentes_encerradas",
      ],
      search: "",
    }),
    computed: {
      headers() {
        let headers = [
          {
            text: "IBGE",
            value: "cod_municipio",
          },
          {
            text: "Município",
            value: "nome_municipio",
            width: "100px",
          },
        ];
        this.kpis.forEach((kpi) =>
          headers.push({
            text: kpi.toUpperCase(),
            value: kpi.toLowerCase(),
          })
        );
        return headers;
      },
      numMunicipios() {
        return this.dados.length;
      },
    },
  };
</script>

<style>
  #div-tabela-kpis-by-municipio {
    min-height: 420px;
  }
  .data-table-kpis table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
  }
  .data-table-kpis table thead tr th {
    font-size: 12px;
    height: 25px;
    font-weight: 400;
  }
</style>
